﻿@import "global.less";

body {
    padding-top: 70px;
    
    /* Styles for validation helpers
      -----------------------------------------------------------*/
    .field-validation-error {
        color: #ff0000;
    }

    .field-validation-valid {
        display: none;
    }

    .input-validation-error {
        border: 1px solid #ff0000;
        background-color: #ffeeee;
    }

    .validation-summary-errors {
        font-weight: bold;
        color: #ff0000;
    }

    .validation-summary-valid {
        display: none;
    }
} 
