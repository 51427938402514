﻿@imagePath: "https://playarkham.blob.core.windows.net/images/";
@import "gloomhaven-fonts.less";

/*http://www.cthulhulives.org/toybox/propdocs/freefonts.html*/

@font-face {
    font-family: Blackletter;
    src: url("../../Content/fonts/BlackHPLHS.otf") format("opentype");
}

@font-face {
    font-family: HeadlineOne;
    src: url("../../Content/fonts/HeadlOneHPLHS.otf") format("opentype");
}

@font-face {
    font-family: HeadlineTwo;
    src: url("../../Content/fonts/HeadlTwoHPLHS.otf") format("opentype");
}

@font-face {
    font-family: Oldstyle;
    src: url("../../Content/fonts/OldstyHPLHS.otf") format("opentype");
}

@font-face {
    font-family: OldstyleItalic;
    src: url("../../Content/fonts/OldstyItaHPLHS.otf") format("opentype");
}

@font-face {
    font-family: OldstyleSmallCaps;
    src: url("../../Content/fonts/OldstySCHPLHS.otf") format("opentype");
}

@font-face {
    font-family: SlabSerif;
    src: url("../../Content/fonts/SlabSerHPLHS.otf") format("opentype");
}

@font-face {
    font-family: Telegram;
    src: url("../../Content/fonts/TelegHPLHS.otf") format("opentype");
}

@font-face {
    font-family: WW2BlackLetter;
    src: url("../../Content/fonts/WW2BlaHPLHS.otf") format("opentype");
}
@font-face {
    font-family: Scriptina;
    src: url("../../Content/fonts/SCRIPTIN.ttf") format("truetype");
}
@font-face {
    font-family: Snake;
    src: url("../../Content/fonts/Snake.ttf") format("truetype");
}
@font-face {
    font-family: Architext;
    src: url("../../Content/fonts/Architex.ttf") format("truetype");
}
@font-face {
    font-family: ArkhamLogo;
    src: url("../../Content/fonts/AH_LCG_v3.ttf") format("truetype");
}
@font-face {
    font-family: ArnoPro;
    src: url("../../Content/fonts/ArnoPro-Arkham.ttf") format("truetype");
}
@font-face {
    font-family: Bolton;
    src: url("../../Content/fonts/Bolton-Arkham.ttf") format("truetype");
}
@font-face {
    font-family: Calibri;
    src: url("../../Content/fonts/Calibri-Arkham.ttf") format("truetype");
}
@font-face {
    font-family: Teutonic;
    src: url("../../Content/fonts/Teutonic-Arkham.ttf") format("truetype");
}
@font-face {
    font-family: Times;
    src: url("../../Content/fonts/timesbi.ttf") format("truetype");
}